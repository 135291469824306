<template>
  <div class="flex-row-fluid col-lg-9" :class="$vuetify.breakpoint.smAndDown ? 'mt-10' : ''">
    <!--begin::Card-->
    <b-form class="form" @submit.stop.prevent="onSubmit">
      <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header py-3">
          <div class="card-title align-items-start flex-column">
            <h3 class="card-label font-weight-bolder text-dark">Endreço</h3>
            <span class="text-muted font-weight-bold font-size-sm mt-1">Atualize suas informações pessoais</span>
          </div>
        </div>
        <!--begin::Body-->
        <div class="card-body">
          <!--begin::Form Edit Address-->
          <b-alert :variant="gettingCep.status" :show="gettingCep.show" dismissible>{{ gettingCep.message }}</b-alert>
          <div class="row">
            <div class="col-xl-3">
              <b-form-group label="Digite o cep">
                <b-form-input
                  v-model="$v.form.zip_code.$model"
                  v-mask="'#####-###'"
                  class="form-control form-control-solid h-auto py-5 px-6 rounded"
                  :state="validateState('zip_code')"
                  @keyup.native="searchCep"
                />
                <b-form-invalid-feedback>Digite seu cep!</b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col-xl-6">
              <b-form-group label="Endereço:" label-for="address">
                <b-form-input
                  v-model="$v.form.address.$model"
                  type="text"
                  class="form-control form-control-solid h-auto py-5 px-6 rounded"
                  :state="validateState('address')"
                />
                <b-form-invalid-feedback>O campo endereço é obrigatório.</b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col-xl-3">
              <b-form-group label="Numéro:" label-for="number">
                <b-form-input
                  ref="number"
                  v-model="$v.form.number.$model"
                  type="text"
                  class="form-control form-control-solid h-auto py-5 px-6 rounded"
                  placeholder=""
                  :state="validateState('number')"
                />
                <b-form-invalid-feedback>O campo número é obrigatório.</b-form-invalid-feedback>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <b-form-group label="Bairro:" label-for="neighborhood">
                <b-form-input
                  v-model="$v.form.neighborhood.$model"
                  type="text"
                  class="form-control form-control-solid h-auto py-5 px-6 rounded"
                  placeholder=""
                  :state="validateState('neighborhood')"
                />
                <b-form-invalid-feedback>O campo bairro é obrigatório.</b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col-xl-3">
              <b-form-group label="Estado:" label-for="state">
                <b-form-select
                  v-model="$v.form.ecosystem_state_id.$model"
                  :options="optionsState"
                  value-field="state_id"
                  text-field="state_name"
                  class="form-control form-control-solid h-auto py-5 px-6 rounded"
                  :state="validateState('ecosystem_state_id')"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-xl-3">
              <b-form-group label="Cidade:" label-for="city">
                <b-form-select
                  v-model="$v.form.ecosystem_city_id.$model"
                  :options="optionsCities"
                  value-field="id"
                  text-field="name"
                  class="form-control form-control-solid h-auto py-5 px-6 rounded"
                  :state="validateState('ecosystem_city_id')"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
        </div>
        <!--begin::Footer Actions-->
        <div class="card-footer d-flex justify-content-between">
          <button
            ref="submit_address"
            type="submit"
            class="btn btn-success font-weight-bold font-size-3 btn-lg"
            :disabled="isSubmitting"
            :class="{ 'spinner spinner-light spinner-right': isSubmitting }"
          >
            Salvar Dados
          </button>
        </div>
        <!--end::Body-->
      </div>
    </b-form>
  </div>
</template>

<script>
import { TheMask } from 'vue-the-mask';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import addressMixin from '@/core/mixins/addressMixin';
import cep from 'cep-promise';

export default {
  components: {},
  mixins: [validationMixin, addressMixin],
  data() {
    return {
      isSubmitting: false,
      fields: [
        { label: 'Data', key: 'date', sortable: false },
        { label: 'Tipo de movimentação', key: 'type', sortable: false },
        { label: 'Usuário', key: 'object_from_to', sortable: false },
        { label: 'Valor', key: 'value', sortable: false, thClass: 'text-right' },
        { label: 'Saldo', key: 'balance', sortable: false, thClass: 'text-right' }
      ],
      gettingCep: {
        show: false,
        status: '',
        message: ''
      },
      form: {
        zip_code: null,
        address: null,
        number: null,
        neighborhood: null,
        ecosystem_state_id: null,
        ecosystem_city_id: null
      },
      optionsState: [],
      optionsCities: []
    };
  },
  computed: mapGetters(['currentAddress']),
  watch: {
    'form.ecosystem_state_id': async function (state) {
      this.form.ecosystem_city_id = null;
      this.optionsCities = await this.getCitiesByStateID(state);

      if (this.currentAddress.ecosystem_city_id) {
        this.form.ecosystem_city_id = this.currentAddress.ecosystem_city_id;
      }
    }
  },
  async mounted() {
    this.$store.dispatch('SET_BREADCRUMB', [{ title: 'Perfil' }, { title: 'Endereço' }]);

    this.optionsState = await this.getStates();

    if (this.currentAddress != null) {
      this.form.zip_code = this.currentAddress.zip_code;
      this.form.address = this.currentAddress.address;
      this.form.number = this.currentAddress.number;
      this.form.neighborhood = this.currentAddress.neighborhood;
      this.form.ecosystem_state_id = this.currentAddress.ecosystem_state_id;
      this.form.ecosystem_city_id = this.currentAddress.ecosystem_city_id;
    }
  },
  validations: {
    form: {
      zip_code: {
        regexZipCode(zip_code) {
          return /^[0-9]{5}-[0-9]{3}$/.test(zip_code);
        }
      },
      address: {
        required
      },
      number: {
        required
      },
      neighborhood: {
        required
      },
      ecosystem_state_id: {
        required
      },
      ecosystem_city_id: {
        required
      }
    }
  },
  methods: {
    async onSubmit() {
      this.isSubmitting = true;

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.isSubmitting = false;
        return;
      }

      // send register request
      await this.$store
        .dispatch('UPDATE_ADDRESS', this.form)
        .then((response) => {
          this.$swal({
            title: response.message,
            icon: 'success'
          });
          this.isSubmitting = false;
        })
        .catch((error) => {
          this.$swal({
            title: 'Ops, aconteu um problema.',
            text: error,
            icon: 'error'
          });
          this.isSubmitting = false;
        });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    searchCep() {
      if (/^[0-9]{5}-[0-9]{3}$/.test(this.$v.form.zip_code.$model)) {
        this.gettingCep.show = true;
        this.gettingCep.message = 'Buscando...';
        this.gettingCep.status = 'warning';

        cep(this.form.zip_code, { timeout: 5000, providers: ['brasilapi'] })
          .then(async (response) => {
            const state = Object.values(this.optionsState).filter((state) => state.state_uf == response.state);
            this.$v.form.ecosystem_state_id.$model = state[0].state_id;

            this.optionsCities = await this.getCitiesByStateID(state[0].state_id);
            const city = Object.values(this.optionsCities).filter((city) => city.name == response.city);
            this.$v.form.ecosystem_city_id.$model = city[0].id;

            this.$v.form.address.$model = response.street;
            this.$v.form.neighborhood.$model = response.neighborhood;
            this.$refs['number'].focus();

            this.gettingCep.message = 'Cep encontrado com sucesso!';
            this.gettingCep.status = 'success';
          })
          .catch(() => {
            this.gettingCep.message = 'Ops... Cep incorreto ou não encontrado!';
            this.gettingCep.status = 'danger';
          });
      }
    }
  }
};
</script>
